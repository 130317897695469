.btn {
    display: inline-block;
    padding: 1.5rem 2.1rem;
    border: 2px solid transparent;
    font-family: var(--ff-bell-black);
    font-size: 1.2rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    transition: background-color ease-in-out 0.05s, border-color ease-in-out 0.05s;
    letter-spacing: 0.4rem;
    white-space: nowrap;
    appearance: none;
    cursor: pointer;
    user-select: none;
    @media (min-width: $screen-md) {
        padding: 1.8rem 3.5rem;
        font-size: 1.4rem;
    }
    &--hollow-grey-border {
        color: #FFFFFF;
        border-color: #4C4C4C;
        background-color: transparent;
        &:focus,
        &:hover {
            background-color: #4C4C4C;
        }
    }
    &--hollow-grey-light-border {
        color: #000000;
        border-color: #E7E7E7;
        &:focus,
        &:hover {
            border-color: var(--grey-light);
            background-color: var(--grey-light);
        }
    }
    &--hollow-grey-light-border-h-white {
        color: #000000;
        border-color: #E7E7E7;
        &:focus,
        &:hover {
            border-color: #FFFFFF;
            background-color: #FFFFFF;
        }
    }
    &--with-plus {
        cursor: pointer;
        &::before {
            display: inline-block;
            content: "";
            width: 1rem;
            height: 1rem;
            overflow: hidden;
            margin-right: 2rem;
            background: url(/build/images/icon-plus-grey-light.svg) no-repeat 50%;
            background-size: 1rem 1rem;
        }
        @media (min-width: $screen-md) {
            padding: 2rem 3rem;
        }
    }
    &--with-arrow {
        span {
            display: inline-block;
            margin-left: 1.7rem;
            overflow: hidden;
            &::after {
                display: inline-block;
                content: "";
                width: 2.1rem;
                height: 0.9rem;
                overflow: hidden;
                background: url(/build/images/icon-right-arrow-grey.svg) no-repeat 50%;
                background-size: 2.1rem 0.9rem;
            }
        }
        &:focus,
        &:hover {
            span::after {
                animation: arrowRight 0.2s cubic-bezier(0.75, 0.25, 0.67, 0.95) forwards;
            }
        }
    }
    &--with-show-hide {
        span {
            display: inline-block;
            position: relative;
            top: 0.2rem;
            width: 1.4rem;
            height: 1.4rem;
            margin-left: 1.2rem;
            &:before,
            &:after {
                content: "";
                position: absolute;
                background-color: #717171;
                transition: transform 0.25s ease-out;
            }

            /* Vertical line */
            &:before {
                top: 0;
                left: 50%;
                width: 2px;
                height: 100%;
                margin-left: -1px;
            }

            /* horizontal line */
            &:after {
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                margin-top: -1px;
            }
        }
        &.is-active {
            background-color: #4C4C4C;
            span {
                cursor: pointer;

                &:before {
                    transform: rotate(90deg);
                }

                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
@keyframes arrowRight {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(100%);
    }
    51% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}
@keyframes arrowLeft {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    51% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}