//
//  Screen Width
//  _____________________________________________
$screen-xs: 320px !default;
$screen-sm: 480px !default;
$screen-md: 767px !default;
$screen-lg: 1023px !default;
$screen-xl: 1400px !default;
$screen-xxl: 1700px !default;

:root {
    // Side Navigation
    --side-nav-width: 6.6rem;
    
    //
    //  Fonts
    //  _____________________________________________
    --ff-bell-bold: "Bell Gothic Std W01 SemiBd", sans-serif;
    --ff-bell-black: "Bell Gothic Std W01 Bold", sans-serif;
    --ff-century: "Century Schoolbook W01", serif;

    //
    //  Colours
    //  _____________________________________________
    --grey-light: #F8F8F8; // Background
    --grey-medium: #666666; // Text; Pre-heading
    --grey-dark: #252525; // Footer; Forms
    --red: #D61829;
    --red-light: #FFDEDE;
    --green: #4AA50D;
    --border-color: #E0E0E0;
}