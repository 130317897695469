.main-header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 2rem 3rem;  
}
@media (min-width: $screen-md) {
    .main-header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 2;
        width: 66px;
        height: 100vh;
        padding: 0;
        background-color: #FFFFFF;
        box-sizing: content-box;
        border-right: 1px solid var(--grey-light);
    }
}
//
//  Control the colour of the site logo and hamburger icon
//  _____________________________________________
body.white-logo-nav {
    .logo svg g,
    .strapline svg g,
    .strapline svg path {
        fill: #FFFFFF;
    }
    .main-header.is-active .logo svg g {
        fill: #000000;
    }
}
@media (max-width: $screen-md) {
    body.white-logo-nav {
        .hamburger-inner, 
        .hamburger-inner::before, 
        .hamburger-inner::after {
            background-color: #FFFFFF;
        }
    }
}
//
//  Logo
//  _____________________________________________
.logo {
    display: block;
    position: relative;
    z-index: 2;
    width: 130px;
}
.logo svg g,
.hero-carousel-content {
    transition: all 0.15s ease-in-out;
}
.strapline svg g {
    transition: all 0.05s ease-in-out;
}
body.white-logo-nav .main-header.black {
    .logo svg g {
        fill: #000000;
    }
    .hero-carousel-content {
        color: #000000;
    }
    .hamburger-inner, 
    .hamburger-inner::before, 
    .hamburger-inner::after {
        background-color: #000000;
    }
}
body.white-logo-nav main.black {
    .scroll-to svg g {
        fill: #000000;
    }
    .hero-carousel-content {
        color: #000000;
    }
}
@media (min-width: $screen-md) {
    .logo {
        position: absolute;
        right: auto;
        top: 4rem;
        left: 12rem;
        z-index: 1;
        width: 160px;
        // width: 11.2vw;
    }
    body.white-logo-nav main.black {
        .logo svg g,
        .strapline svg g {
            fill: #000000;
        }
        .hero-carousel-content {
            color: #000000;
        }
    }
}
@media (min-width: $screen-xl) {
    .logo {
        left: 15rem;
    }
}
//
//  Strapline
//  _____________________________________________
.strapline {
    display: grid;
    place-content: center;
    position: relative;
    z-index: 2;
    margin: -2rem -3rem 2rem -3rem;
    padding: 0.8rem;
    background-color: var(--grey-dark);
    svg {
        width: 17rem;
        height: 2.6rem;
        g {
            fill: #FFFFFF;
        }
    }
}
.strapline.hide-gt-md {
    svg {
        path {
            fill: #FFFFFF;
        }
    }
}
@media (min-width: $screen-md) {
    .strapline {
        position: absolute;
        top: 6.6rem;
        left: 37rem;
        z-index: 1;
        margin: 0;
        background-color: transparent;
        svg {
            width: 26.4rem;
            height: 4rem;
            g {
                fill: #000000;
            }
        }
    }
}
@media (min-width: $screen-xl) {
    .strapline {
        left: 40rem;
    }
}
//
//  Main Navigation
//  _____________________________________________
.main-navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    font-size: 1.4rem;
    background-color: #FFFFFF;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.15s ease-in-out;
    a {
        &:focus,
        &:hover {
            color: #999999;
        }
    }
    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}
.navigation-columns {
    position: relative;
    z-index: 1;
    padding: 17rem 3rem 3rem 3rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
@media (min-width: $screen-md) {
    .main-navigation {
        padding-left: var(--side-nav-width);
        font-size: 1.6rem;
    }
    .navigation-columns {
        display: flex;
        justify-content: center;
        
    }
    .navigation-column {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .navigation-column--1 {
        align-items: flex-end;
        padding-right: 10%;
    }
    .navigation-column--2 {
        padding-left: 10%;
    }
}
@media (min-width: $screen-xl) {
    .navigation-column--1 {
        padding-right: 10%;
    }
    .navigation-column--2 {
        padding-left: 10%;
    }
}
//
//  Main Navigation Items
//  _____________________________________________
.main-nav-items {
    position: relative;
    top: -0.4rem;
    line-height: 1.35;
    li {
        opacity: 1;
        transform: translateY(0);
        line-height: 1;
        margin-bottom: 1rem;
    }
    a {
        font-size: 3rem;
        color: #000000;
        transition: color 0.15s ease-in-out;
    }
}
.main-navigation.is-active {
    .main-nav-items {
        li {
            will-change: transform;
            animation: animateIn 0.7s cubic-bezier(0.67,-0.04,0.82,1.09) both;
            animation-delay: 340ms;
            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    animation-delay: 120ms * $i;
                }
            }
        }
    }
}
@media (min-width: $screen-md) {
    .main-nav-items {
        a {
            font-size: 5.4rem;
        }
    }
}
//
//  Navigation Social
//  _____________________________________________
.navigation-social {
    margin-bottom: 3rem;
    li:not(:last-child) {
        margin-bottom: 0.7rem;
    }
}
@media (min-width: $screen-md) {
    .navigation-social {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        li {
            margin-right: 2.2rem;
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }
}
//
//  Navigation Footer
//  _____________________________________________
.navigation-footer {
    padding-top: 2.8rem;
}
@media (min-width: $screen-md) {
    .navigation-footer {
        margin-top: auto;
        padding-top: 10rem;
    }
}
//
//  Navigation Heading
//  _____________________________________________
.navigation-heading {
    display: block;
    margin-bottom: 0.6rem;
    font-family: var(--ff-bell-black);
    font-size: 1rem;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}
@media (min-width: $screen-md) {
    .navigation-heading {
        font-size: 1.2rem;
    }
}
//
//  Grey Block
//  _____________________________________________
@media (min-width: $screen-md) {
    .navigation-block {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(50% + 3.3rem);
        height: 100%;
        background-color: var(--grey-light);
    }
}
//
//  Header Logo
//  _____________________________________________
@media (max-width: $screen-md) {
    .header-logo {
        max-width: 22rem;
    }
}
//
//  Animate Navigation Items
//  _____________________________________________
@keyframes animateIn {
    0% {
		opacity: 0;
		transform: translate3d(0, 35%, 0);
	}
    70% {
		opacity: 0.3;
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}