.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}
.hide {
    display: none;
}
//
// Border Radius
//  _____________________________________________
.border-radius-3 {
    border-radius: 3px;
}
//
//  Position
//  _____________________________________________
.relative {
    position: relative;
}
//
//  Z-Index
//  _____________________________________________
.zi-1 {
    z-index: 1;
}
//
//  Background Colours
//  _____________________________________________
.bg-white {
    background-color: #FFFFFF;
}
.bg-grey-light {
    background-color: var(--grey-light);
}
.bg-grey-dark {
    background-color: var(--grey-dark);
}
.bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--grey-medium);
    h2,
    .preheading {
        color: #FFFFFF;
    }
}
//
// Limited width blocks
//  _____________________________________________
.limit-width {
    margin-left: auto;
    margin-right: auto;
    &--left {
        margin-left: 0;
    }
}
.limit-width-xs {
    @extend .limit-width;
    max-width: 43rem;
}
.limit-width-xs-sm {
    @extend .limit-width;
    max-width: 70rem;
}
.limit-width-sm {
    @extend .limit-width;
    max-width: 80.5rem;
}
.limit-width-md {
    @extend .limit-width;
    max-width: 93rem;
}
.limit-width-md-lg {
    @extend .limit-width;
    max-width: 103rem;
}
.limit-width-lg {
    @extend .limit-width;
    max-width: 124rem;
}
.limit-width-xl {
    @extend .limit-width;
    max-width: 137rem;
}


.limit-width-md-710 {
    @extend .limit-width;
    @media (min-width: $screen-md) {
        max-width: 71rem;
    }
}
.limit-width-md-740 {
    @extend .limit-width;
    @media (min-width: $screen-md) {
        max-width: 74rem;
    }
}
.limit-width-md-790 {
    @extend .limit-width;
    @media (min-width: $screen-md) {
        max-width: 79rem;
    }
}
.limit-width-md-930 {
    @extend .limit-width;
    @media (min-width: $screen-md) {
        max-width: 93rem;
    }
}
.limit-width-lg-1230 {
    @extend .limit-width;
    @media (min-width: $screen-lg) {
        max-width: 123rem;
    }
}
.limit-width-xl-810 {
    @extend .limit-width;
    @media (min-width: $screen-xl) {
        max-width: 81rem;
    }
}
//
//  Show/Hide
//  _____________________________________________
.hide-lt-sm {
    @media (max-width: $screen-sm) {
        display: none !important;
    }
}
.hide-lt-md {
    @media (max-width: $screen-md) {
        display: none !important;
    }
}
.hide-lt-lg {
    @media (max-width: $screen-lg) {
        display: none !important;
    }
}
.hide-lt-xl {
    @media (max-width: $screen-xl) {
        display: none !important;
    }
}
.hide-gt-md {
    @media (min-width: $screen-md) {
        display: none !important;
    }
}
.hide-gt-lg {
    @media (min-width: $screen-lg) {
        display: none !important;
    }
}
//
// Text Align Center
//  _____________________________________________
.text-center {
    text-align: center;
}
.text-center-sm {
    @media (min-width: $screen-sm) {
        text-align: center;
    }
}
.text-center-md {
    @media (min-width: $screen-md) {
        text-align: center;
    }
}
//
// Text Align Left
//  _____________________________________________
.text-left {
    text-align: left;
}
.text-left-sm {
    @media (min-width: $screen-sm) {
        text-align: left;
    }
}
.text-left-md {
    @media (min-width: $screen-md) {
        text-align: left;
    }
}
//
// Text Align Right
//  _____________________________________________
.text-right {
    text-align: right;
}
.text-right-sm {
    @media (min-width: $screen-sm) {
        text-align: right;
    }
}
.text-right-md {
    @media (min-width: $screen-md) {
        text-align: right;
    }
}
.text-right-lg {
    @media (min-width: $screen-lg) {
        text-align: right;
    }
}
//
//  Bullet List
//  _____________________________________________
.bullet-list {
    ul {
        margin-bottom: 1.1em;
    }
    li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        &::before {
            display: block;
            position: relative;
            left: -0.3rem;
            height: 6px;
            line-height: 6px;
            content: '\2022';
            margin: 1.2rem 0.8rem 0 0;
            font-size: 2.4rem;
            color: var(--blue-dark);
        }
    }
}
//
//  Icon List
//  _____________________________________________
.icon-list {
    li {
        display: flex;
        line-height: 1.5;
        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
    }
}
.icon-list--membership {
    li {
        margin-bottom: 28px;
        span {
            width: 41px;
            height: 41px;
            margin-right: 16px;
        }
        svg {
            max-height: 41px;
            g {
                fill: #FFFFFF;
            }
        }
    }
}
//
//  Grid Helpers
//  _____________________________________________
@media (min-width: $screen-md) {
    .direction-md-row {
        flex-direction: row;
    }
    .no-wrap-md {
        flex-wrap: nowrap;
    }
}
@media (max-width: $screen-md) {
    .bleed-lt-md {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0;
    }
    .col-bleed-lt-md {
        padding: 0;
    }
}
//
//  Pointer
//  _____________________________________________
.cursor-pointer {
    cursor: pointer;
}
//
//  Other Helpers
//  _____________________________________________
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.nooverflow {
    overflow: hidden;
}
//
//  Widths
//  _____________________________________________
.full-width {
    width: 100%;
}
@media (max-width: $screen-md) {
    .lt-md-full-width {
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;
    }
}
@media (max-width: $screen-sm) {
    .lt-sm-full-width {
        width: 100%;
    }
}
//
//  Max Widths
//  _____________________________________________
.mw-450 {
    max-width: 45rem;
}
.mw-570 {
    max-width: 57rem;
}
.object-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
//
//  Other
//  _____________________________________________
.link-style {
    a {
        color: #000000;
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}
//
//  Grid Helper
//  _____________________________________________
@media (min-width: $screen-md) {
    .col-md-auto {
        flex: 0 2 210px;
        width: auto;
    }
}
//
//  Full Height
//  _____________________________________________
.full-height {
    min-height: 100vh;
}
.full-height-hero {
    min-height: calc(100vh - 7.8rem);
}
@media (min-width: $screen-lg) {
    .full-height--lg {
        min-height: 100vh;
    }
}
//
//  Overflow to edge - Mobile
//  _____________________________________________
@media (max-width: $screen-md) {
    .overflow-x-auto {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        width: calc(100% + 3rem);
        .grid {
            flex-wrap: nowrap;
        }
    }
}
//
//  Center inline image like a background image
//  _____________________________________________
.img-crop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: transform .3s ease-in-out;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 134%;
        max-width: none;
        transform: translate(-50%,-50%);
    }
}
//
//  Gradient over patch
//  _____________________________________________
.has-gradient {
    &::after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,rgba(125,185,232,0) 0,rgba(61,91,114,0) 51%,#000);
        opacity: .8;
        transition: opacity .08s ease-in-out;
    }
}
//
//  Gradient over hero
//  _____________________________________________
.has-top-gradient {
    &::before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 30rem;
        background: linear-gradient(0,rgba(125,185,232,0) 0,rgba(61,91,114,0) 20%,#000);
        opacity: .8;
        transition: opacity .08s ease-in-out;
    }
}
.hero-carousel.has-top-gradient {
    &::before {
        z-index: 2;
    }
}
//
//  Video Wrapper
//  _____________________________________________
.video-wrapper {
    position: relative;
    padding-bottom: 56%;
    overflow: hidden;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
//
//  Grid
//  _____________________________________________
.grid-items-mb-20 {
    [class*=col-] {
        padding-bottom: 2rem;
    }
}
.container .grid-bleed-sides {
    margin-right: 0;
    margin-left: 0;
    > [class*=col-] {
        padding-left: 0;
        padding-right: 0;
    }
}
.pr-190 {
    padding-right: 19rem;
}