.main-footer {
    background-color: var(--grey-dark);
    h4 {
        font-family: var(--ff-bell-bold);
        font-size: 2.4rem;
        color: #FFFFFF;
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        line-height: 1.2;
    }
}
.main-footer.main-footer--light {
    background-color: #FFFFFF;
    .footer-links {
        margin-bottom: 2rem;
    }
    .website-by {
        font-family: var(--ff-bell-bold);
        font-size: 1rem;
        color: #7A7A7A;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
    }
}
@media (min-width: $screen-lg) {
    .main-footer.main-footer--light {
        .website-by {
            font-size: 1.1rem;
        }
    }
}
.footer-links {
    display: flex;
    flex-wrap: wrap;
    font-family: var(--ff-bell-bold);
    font-size: 1rem;
    color: #7A7A7A;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    li {
        margin-right: 2rem;
        margin-bottom: 1.4rem;
    }
    .website-by {
        width: 100%;
    }
    a {
        color: #7A7A7A;
        transition: color 0.15s ease-in-out;
        &:focus,
        &:hover {
            color: #FFFFFF;
        }
    }
}

@media (min-width: $screen-lg) {
    .footer-links {
        li {
            margin-bottom: 0;
        }
        .website-by {
            width: auto;
            margin-right: 0;
            margin-left: auto;
        }
    }
    
}
@media (min-width: $screen-md) {
    .main-footer {
        padding-left: 6.6rem;
        h4 {
            font-size: 4rem;
            letter-spacing: 0.4rem;
        }
    }
    .footer-links {
        font-size: 1.1rem;
    }
}
@media (max-width: $screen-md) {
    .footer-logo {
        max-width: 24.5rem;
    }
}