//
//  Resets
//  _____________________________________________
input:focus, 
textarea:focus, 
select:focus{
    outline: none;
}
fieldset {
    border: 0;
    padding: 0;
}
input[type=text],
input[type=search],
input[type=email],
input[type=password],
input[type=number],
textarea {
    font-size: 1.6rem;
    color: #FFFFFF;
    border: 0.1rem solid transparent;
    border-radius: 0;
    background-color: #2F2F2F;
    transition: all 0.05s ease-in-out;
    &:focus {
        border-color: #979797;
        transition: all 0.2s ease-in-out;
    }
    &.ff-has-errors {
        color: var(--red);
        border-color: var(--red);
        background-color: var(--red-light);
    }
}
input[type=text],
input[type=search],
input[type=email],
input[type=password],
input[type=number] {
    width: 100%;
    line-height: 1;
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
    appearance: none;
    @media (min-width: $screen-md) {
        padding: 1.4rem 2rem;
    }
}
textarea {
    width: 100%;
    padding: 2rem;
}
.form-column {
    textarea {
        @media (min-width: $screen-md) {
        height: calc(100% - 4.6rem)
        }
    }
}
input[type=submit] {
    padding: 1.9rem 6rem;
}
.form-group {
    margin-bottom: 2rem;
}
.form-group--checkbox {
    display: flex;
    max-width: 500px;
    label {
        font-weight: 300;
        font-size: 1.1rem;
        text-transform: none;
        color: var(--grey-medium);
    }
    input {
        flex-shrink: 0;
        order: -1;
        margin-right: 12px;
    }
}
.form-group--radio-right {
    display: flex;
    margin-bottom: 10px;
    label {
        max-width: 256px;
        padding-right: 15px;
    }
    input {
        flex-shrink: 0;
    }
}
form {
    .btn {
        padding: 2.2rem 3.5rem;
        @media (min-width: $screen-md) {
            padding: 2.3rem 3.5rem;
        }
    }
}
//
//  Legend
//  _____________________________________________
legend {
    margin-bottom: 2rem;
    font-family: var(--ff-bell-bold);
    font-size: 2.4rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    color: #FFFFFF;
}
@media (min-width: $screen-md) {
    legend {
        margin-bottom: 4rem;
        font-size: 4rem;
        letter-spacing: 0.4rem;
    }
}
//
//  Form Button/Text
//  _____________________________________________
@media (min-width: $screen-md) {
    .form-btn-text {
        display: flex;
        align-items: center;
        .btn {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
    .form-text {
        margin-left: auto;
        padding-left: 3rem;
    }
}

.form-text {
    margin-bottom: 0;
    font-size: 1.2rem;
    color: #999999;
    line-height: 1.5;
    @media (min-width: $screen-md) {
        font-size: 1.4rem;
    }
    a {
        color: #999999;
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}
//
//  Checkbox left aligned
//  _____________________________________________
.checkbox-left {
    display: flex;
    margin-bottom: 20px;
    label {
      padding-top: 2px;
      font-weight: 300;
      font-size: 1.2rem;
      color: var(--grey-medium);
      text-transform: none;
      letter-spacing: normal;
    }
}
//
//  Radio/Checkbox styling
//  _____________________________________________
.field {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

[type=radio],
[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[type=radio],
[type=checkbox] {
    width: 24px;
    height: 24px;
    border: solid 1px var(--border-color);
    margin-right: 8px;
    position: relative;
    background-color: #FFFFFF;
    &.mr-0 {
        margin-right: 0;
    }
}
[type=checkbox] {
    border-radius: 2px;
}
[type=radio]:checked::before,
[type=checkbox]:checked::before {
    content: "";
    width: 14px;
    height: 14px;
    background-color: var(--blue-dark);
    position: absolute;
    top: 4px;
    left: 4px;
}
[type=checkbox]:checked::before {
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check.svg") no-repeat center center;
}
// [type=radio]:focus,
// [type=checkbox]:focus {
//     box-shadow: 0 0 0 3px var(--blue-dark);  
//     outline: 3px solid transparent; /* For Windows high contrast mode. */
// }
[type=radio],
[type=radio]:checked::before {
    border-radius: 100%;
}

label,
.faux-label {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-family: var(--ff-bell-bold);
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1;
    color: #FFFFFF;
    @media (min-width: $screen-md) {
        margin-bottom: 1.4rem;
        font-size: 1.2rem;
    }
}

button {
    &:focus {
        outline: 0;
    }
}
//
//  Select
//  _____________________________________________
select {
    display: block;
    font-size: 1.6rem;
    font-family: sans-serif;
    font-weight: 300;
    color: #9D9D9D;
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    appearance: none;
    background-color: #FFFFFF;
    background-image: url(/images/icon-select-arrow-dark-grey.svg);
    background-repeat: no-repeat, repeat;
    background-position: right 20px top 50%, 0 0;
}
//
//  Form Messages
//  _____________________________________________
.ff-form-errors {
    display: none;
}
.ff-errors {
    padding-top: 0.5rem;
    color: var(--red);
    font-size: 1.6rem;
}
.ff-form-success {
    margin-bottom: 3rem;
    padding: 1.8rem 2rem;
    color: #008008;
    line-height: 1.5;
    border: 1px solid #008008;
    background-color: #D2E4D7;
    p {
        margin: 0;
    }
}
//  Placeholder
//  _____________________________________________
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey-medium);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey-medium);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey-medium);
}
.ff-has-errors::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--red);
        opacity: 1;
        /* Firefox */
}
.ff-has-errors:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey-medium);
}
.ff-has-errors::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey-medium);
}
//
//  Remove Search input icon
//  _____________________________________________
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
}
//
//  Errors
//  _____________________________________________
ul.errors {
    li {
        font-family: var(--ff-bell-bold);
        text-transform: uppercase;
        letter-spacing: .2rem;
        line-height: 1;
        font-size: 1.2rem;
        padding-top: 1rem;
        color: var(--red) !important;
    }
}
.pristine-error {
    font-family: var(--ff-bell-bold);
    text-transform: uppercase;
    letter-spacing: .2rem;
    line-height: 1;
    font-size: 1.2rem;
    padding-top: 1rem;
    color: var(--red) !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: #FFFFFF !important;
    -webkit-box-shadow: 0 0 0px 1000px #2F2F2F inset;
}
//
//  Form Columns
//  _____________________________________________
.form-column {
    margin-bottom: 2rem;
}
@media (min-width: $screen-md) {
    form[data-handle=fitoutEnquiry] {
        textarea {
            height: calc(100% - 4.6rem);
        }
    }
}
@media (min-width: $screen-lg) {
    .form-row {
        display: flex;
        margin-right: -0.7rem;
        margin-left: -0.7rem;
    }
    .form-column {
        flex-grow: 1;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}
//
//  Form Grid
//  _____________________________________________
@media (min-width: $screen-md) {
    .form-grid {
        display: flex;
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .form-grid-column {
        width: 50%;
        padding: 0 2rem;
    }
}