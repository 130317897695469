*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}
body {
    position: relative;
    margin: 0 auto;
    background-color: var(--grey-light);
    &.ns {
        overflow: hidden;
    }
}
a {
    text-decoration: none;
    color: var(--grey-medium);
}
li {
    list-style-type: none;
}
img {
    max-width: 100%;
    height: auto;
}
[v-cloak] {
   display: none;
}
//
//  Main
//  _____________________________________________
@media (min-width: $screen-md) {
    main {
        padding-left: var(--side-nav-width);
    }
}
//
//  Container
//  _____________________________________________
.container {
    max-width: none;
    margin-left: 0;
    padding-left: 3rem;
    padding-right: 3rem;
}
@media (min-width: $screen-md) {
    .container {
        padding-left: 5.4rem;
        padding-right: 5.4rem;
        
    }
}
@media (min-width: $screen-lg) {
    .container {
        padding-left: 5.4rem;
        padding-right: 5.4rem;
        :root &--right-edge {
            padding-right: 0;
        }
    }
}
@media (min-width: $screen-xl) {
    .container {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
}
//
//  Hero Carousel
//  _____________________________________________
.hero-carousel {
    background-color: var(--grey-medium);
    a {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.hero-cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hero-half {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media (min-width: $screen-lg) {
    .hero-half-img-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
@media (max-width: $screen-lg) {
    .hero-half-img-wrap {
        img {
            width: calc(100% + 10.8rem);
            height: auto;
            max-width: none;
            object-fit: unset;
            margin-left: -5.4rem;
        }
    }
}
@media (max-width: $screen-md) {
    .hero-half-img-wrap {
        img {
            width: calc(100% + 6rem);
            margin-left: -3rem;
        }
    }
}
.hero-carousel-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 0 3.5rem 3rem;
    font-size: 1.2rem;
    color: #FFFFFF;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    strong {
        font-family: var(--ff-bell-black);
    }
    span {
        font-family: var(--ff-bell-bold);
    }
}
@media (min-width: $screen-md) {
    .hero-carousel-content {
        padding: 0 0 6rem 8.4rem;
        font-size: 1.6rem;
    }
}
//
//  Scroll To
//  _____________________________________________
.scroll-to {
    position: absolute;
    left: 0;
    bottom: 5rem;
    width: 100%;
    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    }
}
.scroll-to--full {
    display: flex;
    justify-content: center;
    left: 0;
    span {
        animation: bounce 2s infinite;
    }
}
body.white-logo-nav {
    .scroll-to {
        svg g {
            fill: #FFFFFF;
        }
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-1rem);
    }
    60% {
      transform: translateY(-0.5rem);
    }
}
//
//  Swiper
//  _____________________________________________
.swiper-default {
    position: relative;
    z-index: 0;
}
//
//  Project Filter
//  _____________________________________________
.projects-filter {
    display: flex;
    align-items: center;
    width: calc(100% + 3rem);
    margin-bottom: 3.5rem;
    padding-bottom: 0.2rem;
    overflow-x: auto;
    display: none;
    li {
        flex-shrink: 0;
        margin-right: 2rem;
        &:last-child {
            padding-right: 3rem;
        }
    }
    a {
        display: inline-block;
        position: relative; 
        font-family: var(--ff-bell-black);
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 0.4rem;
        color: #999999;
        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            left: 0;
            right: 100%;
            bottom: -0.2rem;
            background: #DEDEDE;
            height: 1px;
            transition-property: right;
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
        }
        &:hover:before {
            right: 0;
        }
        &.is-active {
            color: #000000;
            &:before {
                right: 0;
            }
        }
    }
}
@media (min-width: $screen-md) {
    .projects-filter {
        width: 100%;
        margin-bottom: 6rem;
        li {
            margin-right: 3rem;
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
//
//  Projects Grid Block
//  _____________________________________________
.block-projects-grid {
    display: flex;
    flex-direction: column;
}

@media (min-width: $screen-md) {
    .bpg__1 {
        order: 1;
    }
    .bpg__2 {
        order: 3;
    }
    .bpg__3 {
        position: relative;
        order: 2;
    }
}
@media (min-width: $screen-xl) {
    .block-projects-grid {
        .btn {
            position: absolute;
            right: 0;
            top: -9.5rem;
        }
    }
}
//
//  Projects Grid
//  _____________________________________________
.projects-grid {
    display: grid;
    grid-gap: 2rem;
    content-visibility: auto;
    contain-intrinsic-size: 90rem;
}
.projects-grid__item {
    background-color: var(--grey-medium);
}
.projects-grid-cls {
    min-height: 199rem;
}
@media (min-width: $screen-sm) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: $screen-lg) {
    .projects-grid-cls {
        min-height: 60rem;
    }
    .projects-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .projects-grid__item:nth-child(6n+1),
    .projects-grid__item:nth-child(6n+6) {
        grid-column: span 2;
        grid-row: span 2;
    }
    .projects-grid__item:nth-child(6n+2) {
        grid-column: span 2;
    }
    .projects-grid__item:nth-child(6n+3) {
        grid-column: span 1;
    }
    .projects-grid__item:nth-child(6n+4) {
        grid-column: span 1;
    }
    .projects-grid__item:nth-child(6n+5) {
        grid-column: span 2;
        grid-row: span 1;
    }
    .projects-grid__item:nth-child(6n+6) {
        grid-column: span 2;
        grid-row: span 1;
    }
}
//
//  Project Item - In Projects Grid
//  _____________________________________________
.project-item {
    position: relative;
    min-height: 31.5rem;
    overflow: hidden;
    a {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        min-height: 31.5rem;
        padding: 3rem 2rem;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--grey-medium);
    }
    h3 {
        z-index: 1;
        margin-bottom: 1rem;
        font-size: 2rem;
        color: #FFFFFF;
    }
    .preheading {
        z-index: 1;
        margin-bottom: 0;
        color: #FFFFFF;
    }
}
.project-item__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 145%;
    transition: background-size 0.25s ease-in-out;
}
@media (min-width: $screen-sm) {
    .project-item {
        min-height: 42vw;
        a {
            min-height: 42vw;
            transition: background-color 0.15s ease-in-out;
            &:focus,
            &:hover {
                background-color: darken(#666666,5);
                .project-item__img {
                    background-size: 116%;
                }
            }
        }
        h3 {
            font-size: 2.6rem;
        }
        .preheading {
            font-size: 1.6rem;
        }
        
    }
    .project-item__img {
        background-size: 120%;
    }
}
@media (min-width: $screen-md) {
    .project-item {
        min-height: 30vw;
        a {
            min-height: 30vw;
        }
    }
}
@media (min-width: $screen-lg) {
    .project-item {
        min-height: 29rem;
        a {
            min-height: 29rem;
        }
    }
    .projects-grid__item:nth-child(6n+1) {
        min-height: 60.2rem;
        .project-item,
        a {
            min-height: 60.2rem;
        }
        .project-item__img {
            background-size: 145%;
        }
        a {
            &:focus,
            &:hover {
                .project-item__img {
                    background-size: 140%;
                }
            }
        }
    }
    
}
@media (min-width: $screen-xl) {
    .project-item {
        h3 {
            font-size: 3rem;
        }
    }
}
@media (min-width: $screen-xxl) {
    .project-item {
        min-height: 37.6rem;
        a {
            min-height: 37.6rem;
            padding: 5rem;
        }
    }
    .projects-grid__item:nth-child(6n+1) {
        min-height: 77.2rem;
        .project-item,
        a {
            min-height: 77.2rem;
        }
        .project-item__img {
            background-size: 120%;
        }
        a {
            &:focus,
            &:hover {
                .project-item__img {
                    background-size: 116%;
                }
            }
        }
    }
}
//
//  Square Target
//  _____________________________________________
.block-projects-square {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 44rem;
}
@media (min-width: $screen-lg) {
    .block-projects-square {
        display: block;
    }
}
//
//  Title & 3 Cards - Latest News Block
//  _____________________________________________
@media (min-width: $screen-lg) {
    .title-3-cards {
        display: flex;
        > div:first-child {
            width: 25rem;
            padding-right: 3rem;
        }
        > div:last-child {
            flex-grow: 1;
        }
    }
}
@media (min-width: $screen-xl) {
    .title-3-cards {
        > div:first-child {
            width: 35rem;
        }
    }
}
//
//  White Card - Latest News block
//  _____________________________________________
.white-card {
    background-color: rgba(255,255,255,0.95);
    transition: background-color 0.15s ease-in-out;
    a {
        display: flex;
        flex-direction: column;
        padding: 3.5rem 2rem;
    }
    .preheading {
        margin-bottom: 0.8rem;
        color: #000000;
    }
    h3 {
        margin-bottom: 5rem;
    }
    footer {
        margin-top: auto;
    }
    &:focus,
    &:hover {
        background-color: rgba(255,255,255,1);
        .read-more span::after {
            animation: arrowRight 0.2s cubic-bezier(0.75, 0.25, 0.67, 0.95) forwards;
        }
    }
}
@media (min-width: $screen-md) {
    .white-card {
        height: 100%;
        a {
            height: 100%;
        }
        h3 {
            font-size: 1.6rem;
        }
    }
}
@media (min-width: $screen-lg) {
    .white-card {
        h3 {
            margin-bottom: 14rem;
        }
    }
}
@media (min-width: $screen-xl) {
    .white-card {
        a {
            padding: 10.4rem 4rem 4rem 4rem;
        }
        h3 {
            font-size: 2.5rem;
        }
    }
}
@media (min-width: $screen-xxl) {
    .white-card {
        h3 {
            font-size: 3rem;
        }
    }
}
@media (max-width: $screen-md) {
    .white-card {
        min-width: 27rem;
    }
}
//
//  Read More - News Card
//  _____________________________________________
.read-more {
    display: inline-flex;
    align-items: center;
    font-family: var(--ff-bell-black);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: #000000;
    line-height: 1.2;
    span {
        display: inline-block;
        margin-left: 1.7rem;
        overflow: hidden;
        &::after {
            display: inline-block;
            content: "";
            width: 2.1rem;
            height: 0.9rem;
            overflow: hidden;
            background: url(/build/images/icon-right-arrow-grey.svg) no-repeat 50%;
            background-size: 2.1rem 0.9rem;
        }
    }
}
//
//  Text Images Opposite
//  _____________________________________________
.images-opposite {
    display: flex;
    justify-content: space-between;
    .grid {
        max-width: 99rem;
    }
    p + .btn {
        margin-top: 1rem;
    }
}
.images-opposite-mob {
    display: flex;
    width: calc(100% + 3rem);
    margin-right: -3rem;
    margin-bottom: 4.5rem;
    > div {
        min-height: 18.3rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.images-opposite-mob__1 {
    width: 40%;
}
.images-opposite-mob__2 {
    width: 60%;
    margin-left: 1.5rem;
}
@media (min-width: $screen-md) {
    .images-opposite {
        h2 {
            margin-bottom: 6rem;
        }
    }
}
@media (min-width: $screen-xl) {
    .images-opposite {
        h2 {
            margin-bottom: 8rem;
        }
    }
    .images-opposite__lg-img {
        display: block;
        margin-right: -5.4rem;
        padding-left: 10rem;
    }
}
//
//  Quote
//  _____________________________________________
blockquote {
    padding-left: 2rem;
    border-left: 0.5rem solid #FFFFFF;
    p {
        margin-bottom: 2rem;
        font-size: 2rem;
        color: #000000;
        line-height: 1.4;
    }
    footer {
        font-family: var(--ff-bell-bold);
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
    }
}
@media (min-width: $screen-md) {
    blockquote {
        padding-left: 4.2rem;
        p {
            margin-bottom: 6rem;
            font-size: 3rem;
        }
        footer {
            font-size: 1.2rem;
        }
    }
}
//
//  Back Link
//  _____________________________________________
.back-link {
    display: inline-flex;
    align-items: center;
    margin-bottom: 2rem;
    font-family: var(--ff-bell-black);
    font-size: 1rem;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.2rem;
    span {
        display: inline-block;
        margin-right: 1.7rem;
        overflow: hidden;
        &::after {
            display: inline-block;
            content: "";
            width: 2.1rem;
            height: 0.9rem;
            overflow: hidden;
            background: url(/build/images/icon-left-arrow-grey.svg) no-repeat 50%;
            background-size: 2.1rem 0.9rem;
        }
    }
    &:focus,
    &:hover {
        span::after {
            animation: arrowLeft 0.2s cubic-bezier(0.75, 0.25, 0.67, 0.95) forwards;
        }
    }
}
@media (min-width: $screen-md) {
    .back-link {
        font-size: 1.2rem;
    }
}
@media (min-width: $screen-xxl) {
    .back-link {
        position: absolute;
        left: 8.5rem;
        top: 11.5rem;
        &--news {
            top: 26.5rem;
        }
        &--expertise {
            position: relative;
            left: auto;
            top: auto;
        }
    }
}
//
//  Project Overview Grid
//  _____________________________________________
.project-overview {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    > li {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.project-overview__item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 1.2;
    border-top: 0.6rem solid #FFFFFF;
}
.project-overview__heading {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--ff-bell-black);
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.2rem;
}
@media (min-width: $screen-md) {
    .project-overview {
        margin-left: -3rem;
        margin-right: -3rem;
        > li {
            width: 33.333333333%;
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
    .project-overview__item {
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.8rem;
    }
    .project-overview__heading {
        font-size: 1.4rem;
    }
}
//
//  Image/Text Card
//  _____________________________________________
.img-text-card {
    figure {
        margin-bottom: 2.4rem;
        img {
            width: 100%;
        }
    }
    h3 {
        font-family: var(--ff-bell-bold);
        font-size: 1.8rem;
    }
    p {
        line-height: 1.6;
    }
}
@media (min-width: $screen-md) {
    .img-text-card {
        figure {
            margin-bottom: 3.2rem;
        }
        h3 {
            font-size: 2.4rem;
        }
    }
}
//
//  Sectors
//  _____________________________________________
.block-sectors {
    p {
        line-height: 1.5;
    }
}
.sectors-list {
    li {
        &:first-child {
            a {
                border-top: 0.3rem solid #FFFFFF;
            }
        }
    }
    a {
        display: block;
        padding: 2rem;
        font-family: var(--ff-bell-black);
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        color: #FFFFFF;
        line-height: 1;
        border-bottom: 0.3rem solid #FFFFFF;
        transition: background-color 0.15s ease-in-out;
        &:focus,
        &:hover {
            background-color: rgba(0,0,0,0.4);
        }
    }
}
@media (min-width: $screen-sm) {
    .sectors-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -2rem;
        margin-right: -2rem;
        li {
            width: 50%;
            padding-left: 2rem;
            padding-right: 2rem;
            &:nth-child(2) {
                a {
                    border-top: 0.3rem solid #FFFFFF;
                }
            }
        }
    }
}
@media (min-width: $screen-md) {
    .sectors-list {
        a {
            padding: 3.3rem 2rem;
            letter-spacing: 0.4rem;
        }
    }
}
@media (min-width: $screen-lg) {
    .sectors-grid {
        display: flex;
        > div:first-child {
            max-width: 61rem;
        }
        > div:last-child {
            flex-grow: 1;
            padding-left: 9rem;
        }
    }
}
//
//  Block Text
//  _____________________________________________
.block-text {
    p + h2 {
        padding-top: 4rem;
    }
}
//
//  Block Related Projects
//  _____________________________________________
.block-related-projects {
    .grid > div {
        margin-bottom: 2rem;
    }
    .project-item {
        min-height: 31rem;
    }
}
@media (min-width: $screen-md) {
    .block-related-projects {
        .grid > div {
            margin-bottom: 0;
        }
        .project-item {
            min-height: 40rem;
        }
    }
}
@media (min-width: $screen-xxl) {
    .block-related-projects {
        .project-item {
            min-height: 70rem;
        }
    }
}
//
//  Block Expertise
//  _____________________________________________
.block-expertise {
    .project-item a {
        @media (min-width: $screen-xl) {
            padding-left: 3rem;
            padding-bottom: 1rem;
        }
        h3 {
            margin-bottom: 2rem;
        }
    }
}
//
//  Text/Image block
//  _____________________________________________
.block-text-image {
    figure {
        margin-bottom: 3rem;
    }
    img {
        width: 100%;
    }
}
.block-text-image + .block-text-image {
    margin-top: -5rem;
}
@media (min-width: $screen-lg) {
    .block-text-image {
        figure {
            margin-bottom: 0;
        }
    }
    .block-text-image + .block-text-image {
        margin-top: -15rem;
        
    }
    .block-text-image:nth-child(2n+1) {
        [class*=col-]:last-child  {
            order: -1;
        }
        .block-text-image__text {
            padding-left: 0;
            padding-right: 10.5rem;
        }
    }
    .block-text-image__text {
        padding-left: 10.5rem;
        padding-right: 5.4rem;
    }
}
@media (min-width: $screen-xl) {
    .block-text-image__text {
        padding-right: 8.4rem;
    }
}
//
//  Image Block
//  _____________________________________________
.block-image {
    img {
        width: 100%;
    }
}
//
//  Contact Block
//  _____________________________________________
.contact-block {
    background-color: var(--grey-dark);
    .preheading {
        color: #999999;
    }
    h3 {
        max-width: 66%;
        font-family: var(--ff-bell-bold);
        color: #FFFFFF;
        line-height: 1.4;
    }
}
.contact-block__details {
    position: relative;
    padding: 4rem 2.5rem 5rem 2.5rem;
    figure {
        position: absolute;
        right: 2.5rem;
        bottom: 0;
    }
    img {
        width: 21.2vw;
        max-width: 30rem;
    }
    p {
        margin-bottom: 0;
    }
    &.no-pad-btm {
        padding-bottom: 0;
    }
}
.contact-block__form {
    max-height: 0;
    transition: max-height 0.8s ease-in-out;
    overflow: hidden;
    &.is-active {
        max-height: 100rem;
        transition: max-height 0.8s ease-in-out;
    }
}
.contact-block__form-inner {
    padding: 0 2.5rem 3.5rem 2.5rem;
}
.block-contact {
    form {
        padding-top: 3.5rem;
    }
    hr {
        border: 0;
        height: 1px;
        background: #3D3D3D;
    }
}
@media (min-width: $screen-lg) {
    .contact-block {
        h3 {
            font-size: 3rem;
        }
    }
    .contact-block__details {
        padding: 6.5rem 4rem 5rem 4rem;
        figure {
            right: 4rem;
        }
    }
    .contact-block__form-inner {
        padding: 0 4rem 6.5rem 4rem;
    }
    .block-contact {
        form {
            padding-top: 5rem;
        }
        p + .contact-block {
            margin-top: 6rem;
        }
    }
}
//
//  Process Block
//  _____________________________________________
.process-list {
    h3 span {
        font-family: var(--ff-bell-bold);
    }
}
.process-list__item {
    padding-top: 2.6rem;
    border-top: 0.6rem solid var(--grey-light);
}
@media (min-width: $screen-md) {
    .process-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -3rem;
        margin-right: -3rem;
        li {
            width: 50%;
            padding-left: 3rem;
            padding-right: 3rem;
            padding-bottom: 2rem;
        }
        h3 {
            font-size: 2rem;
        }
    }
    .process-list__item {
        padding-top: 4rem;
    }
}
@media (min-width: $screen-lg) {
    .process {
        display: flex;
        > div:first-child{
            width: 37%;
            padding-right: 7%;
        }
        > div:last-child{
            width: 63%;
        }
    }
}
@media (min-width: $screen-xl) {
    .process-list {
        li {
            width: 33.333333333%;
            padding-bottom: 4.5rem;
        }
    }
}
//
//  Jobs
//  _____________________________________________
.job-overview {
    display: flex;
    flex-wrap: wrap;
    max-width: 62rem;
    margin-left: -1rem;
    margin-right: -1rem;
    > li {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.job-overview__item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 1.2;
    border-top: 0.6rem solid var(--grey-light);
}
.job-overview__heading {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--ff-bell-black);
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.2rem;
}
@media (min-width: $screen-md) {
    .job-overview {
        margin-left: -3rem;
        margin-right: -3rem;
        > li {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}
//
//  Job Links
//  _____________________________________________
.job-links {
    margin-bottom: 6rem;
    padding-top: 2rem;
    li {
        margin-bottom: 1.5rem;
    }
    a {
        display: inline-block;
        position: relative; 
        font-family: var(--ff-bell-black);
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        color: #000000;
        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            left: 0;
            right: 0;
            bottom: -0.2rem;
            background: #C9C9C9;
            height: 1px;
            transition-property: left;
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
        }
        &:hover:before {
            left: 100%;
        }
    }
}
@media (min-width: $screen-md) {
    .job-links {
        padding-top: 3rem;
        a {
            letter-spacing: 0.4rem;
        }
    }
}
//
//  Text Patches Block
//  _____________________________________________
.block-text-patches {
    h2,
    h3 {
        color: #FFFFFF;
    }
    p {
        color: #999999;
    }
    .process-list__item{ 
        border-color: #323232;
    }
}
//
//  Team Member
//  _____________________________________________
.team-member {
    figure {
        margin-bottom: 3rem;
    }
    .preheading {
        margin-bottom: 1rem;
    }
    h3 {
        font-family: var(--ff-bell-bold);
    }
}
@media (min-width: $screen-xxl) {
    .team-member {
        h3 {
            font-size: 2.8rem;
        }
    }
}
//
//  News
//  _____________________________________________

    .news-grid {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .news-grid__item {
        padding: 1.5rem;
    }
@media (min-width: $screen-md) {
    .news-grid {
        flex-direction: row;
    }
    .news-grid__item {
        width: 50%;
    }
}
@media (min-width: $screen-xl) {
    .news-grid__item {
        width: 33.333333333%;
    }
}
//
//  News Card
//  _____________________________________________
.news-card {
    background-color: #FFFFFF;
    &:focus,
    &:hover {
        h2 {
            color: #999999;
        }
        .read-more span::after {
            animation: arrowRight 0.2s cubic-bezier(0.75, 0.25, 0.67, 0.95) forwards;
        }
    }
    a {
        display: flex;
        flex-direction: column;
    }
    figure {
        background-color: var(--grey-medium);
    }
    img {
        width: 100%;
    }
    header,
    footer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    header {
        margin-bottom: 2rem;
        padding-top: 5rem;
    }
    footer {
        margin-top: auto;
        padding-bottom: 4.5rem;
    }
    .preheading {
        margin-bottom: 0.6rem;
        color: #000000;
    }
    h2 {    
        font-family: var(--ff-bell-black);   
        transition: color 0.15s ease-in-out;  
    }
}
@media (min-width: $screen-md) {
    .news-card {
        height: 100%;
        a {
            height: 100%;
        }
    }
}
@media (min-width: $screen-lg) {
    .news-card {
        header,
        footer {
            padding-left: 4rem;
            padding-right: 4rem;
        }
        h2 {
            font-size: 3rem;
        }
    }
}
//
//  Contact
//  _____________________________________________
.main-contact {
    line-height: 2.2;
    font-size: 1.4rem;
    a[href*=mailto] {
        font-family: var(--ff-bell-bold);
        letter-spacing: 0.3rem;
        color: var(--grey-dark);
        text-transform: uppercase;
    }
    a[href*=tel] {
        font-family: var(--ff-bell-bold);
        letter-spacing: 0.3rem;
        text-transform: uppercase;
    }
}
.contact-grid__column {
    .contact-block {
        margin-left: -3rem;
        margin-right: -3rem;
    }
}
@media (min-width: $screen-md) {
    .main-contact {
        font-size: 2rem;
    }
    .contact-grid__column {
        .contact-block {
            margin-left: -5.4rem;
            margin-right: -5.4rem;
        }
    }
}
@media (min-width: $screen-xl) {
    .contact-grid__column {
        .contact-block {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .contact-grid {
        display: flex;
    }
    .contact-grid__column {
        &:first-child {
            width: percentage(830px/1855px);
            max-width: 83rem;
        }
        &:last-child {
            flex-grow: 1;
            max-width: 102.5rem;
            position: relative;
            .contact-block {
                position: relative;
                z-index: 1;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                width: 9999999rem;
                height: 100%;
                background-color: var(--grey-dark);
            }
        }
    }
}
.locations {
    margin-bottom: 4rem;
}
.location {
    strong {
        display: flex;
        font-family: var(--ff-bell-bold);
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        color: #000000;
    }
}
.location__telephone {
    display: block;
    padding-top: 3rem;
}
@media (min-width: $screen-md) {
    .locations {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5.6rem;
        li {
            width: 50%;
        }
    }
    .location {
        margin-bottom: 5rem;
        padding-right: 4rem;
    }
}
@media (min-width: $screen-xxl) {
    .locations {
        padding-top: 0;
    }
}
//
//  Collapsible Panels
//  _____________________________________________
@media (max-width: $screen-md) {
    .cp-heading {
        display: flex;
        justify-content: space-between;
        border-top: 0.1rem solid #E7E7E7;
        font-size: 1.2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        span {
            display: inline-block;
            position: relative;
            top: 0.5rem;
            width: 1rem;
            height: 1rem;
            margin-left: 1.2rem;
            &:before,
            &:after {
                content: "";
                position: absolute;
                background-color: #AAAAAA;
                transition: transform 0.25s ease-out;
            }

            /* Vertical line */
            &:before {
                top: 0;
                left: 50%;
                width: 2px;
                height: 100%;
                margin-left: -1px;
            }

            /* horizontal line */
            &:after {
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                margin-top: -1px;
            }
        }
    }
    .cp-body {
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
    }
    .cp-body__inner {
        padding-bottom: 2rem;
    }
    .locations {
        border-bottom: 0.1rem solid #E7E7E7;
    }
    .location.is-active {
        .cp-heading {
            span {
                cursor: pointer;
                &:before {
                    transform: rotate(90deg);
                }
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        .cp-body {
            max-height: 20rem;
            transition: max-height 0.8s ease-in-out;
        }
    }
}
//
//  Block Top Spacing
//  _____________________________________________
.block-image + .block-gallery,
.block-quote + .block-gallery  {
    margin-top: -5rem;
    @media (min-width: $screen-md) {
        margin-top: -10rem;
    }
    @media (min-width: $screen-lg) {
        margin-top: -14rem;
    }
}
.block-image + .block-quote {
    margin-top: -2rem;
}
.block-image + .block-accreditations {
    margin-top: -3rem;
    @media (min-width: $screen-md) {
        margin-top: -12.5rem;
    }
}
.block-job + .block-job {
    margin-top: -6rem;
    @media (min-width: $screen-md) {
        margin-top: -14.5rem;
    }
}
.block-text + .block-contact {
    margin-top: -5rem;
    @media (min-width: $screen-md) {
        margin-top: -9rem;
    }
    @media (min-width: $screen-lg) {
        margin-top: -15rem;
    }
}
.block-contact + .block-image {
    margin-top: -4rem;
    @media (min-width: $screen-md) {
        margin-top: -10rem;
    }
}
.block-contact + .block-gallery {
    margin-top: -4rem;
    @media (min-width: $screen-lg) {
        margin-top: -14rem;
    }
}
.block-project-overview + .block-image {
    margin-top: -7rem;
    @media (min-width: $screen-md) {
        margin-top: -13.5rem;
    }
}
.block-project-overview + .block-text {
    margin-top: -3rem;
    @media (min-width: $screen-md) {
        margin-top: -7.5rem;
    }
}
.block-project-overview + .block-contact {
    margin-top: -4rem;
    @media (min-width: $screen-md) {
        margin-top: -8rem;
    }
    @media (min-width: $screen-md) {
        margin-top: -12.5rem;
    }
}
.block-video + .block-contact {
    margin-top: -4rem;
    @media (min-width: $screen-md) {
        margin-top: -8rem;
    }
    @media (min-width: $screen-lg) {
        margin-top: -12.5rem;
    }
}
//
//  Share
//  _____________________________________________
.share {
    display: none;
    li:first-child {
        .share__item {
            background-color: var(--grey-dark);
        }
    }
}
.share__item {
    display: grid;
    place-content: center;
    width: 6.2rem;
    height: 6.2rem;
    background-color: #FFFFFF;
    transition: background-color 0.05s ease-in-out;
    &:hover {
        background-color: var(--grey-dark);
        svg g {
            fill: #FFFFFF;
        }
    }
}
@media (min-width: $screen-md) and (max-width: $screen-xxl) {
    .share {
        display: block;
        margin-top: -2rem;
        margin-bottom: 8rem;
        ul {
            display: flex;
            width: 24rem;
        }
    }
}
@media (min-width: $screen-xxl) {
    .share {
        display: block;
        position: absolute;
        left: 8.5rem;
        top: -7rem;
    }
}
//
//  Cookie Alert
//  _____________________________________________
.cookie-alert {
    position: fixed;
    right: 2rem;
    bottom: 5rem;
    z-index: 2;
    padding: 2.4rem;
    width: calc(100% - 4rem);
    max-width: 37rem;
    color: #FFFFFF;
    background-color: var(--grey-dark);
    p {
        font-size: 1.6rem;
    }
    footer {
        display: flex;
        justify-content: space-between;
    }
    a,
    strong {
        font-family: var(--ff-bell-black);
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        text-decoration: underline;
        cursor: pointer;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}
@media (min-width: $screen-md) {
    .cookie-alert {
        right: 4rem;
        p {
            font-size: 1.8rem;
        }
        a,
        strong {
            font-size: 1.2rem;
        }
    }  
}