.hamburger {
    position: absolute;
    right: 1.5rem;
    top: 62%;
    transform: translateY(-50%);
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.8s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    z-index: 2;
    overflow: visible;
}
@media (min-width: $screen-md) {
    .hamburger-button {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--side-nav-width);
        height: 100%;
        z-index: 2;
        background-color: #FFFFFF;
        cursor: pointer;
        &:not(.is-active) {
            &:focus,
            &:hover {
                .hamburger--squeeze .hamburger-inner::before {
                    transform: translateY(-0.2rem);
                }
                .hamburger--squeeze .hamburger-inner::after {
                    transform: translateY(0.2rem);
                }
            }
        }
        
        
    }
    .hamburger {
        top: 50%;
        right: auto;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
.hamburger-button.is-active .hamburger-inner,
.hamburger-button.is-active .hamburger-inner::before,
.hamburger-button.is-active .hamburger-inner::after {
    background-color: #000000;
}

.hamburger-box {
    width: 2rem;
    height: 1.6rem;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 2rem;
    height: 0.2rem;
    background-color: #000000;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.25s;
    transition-timing-function: ease;
}
.hamburger-inner {
    transition: background-color 0.15s ease-in-out, transform 0.2s ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -0.7rem;
}

.hamburger-inner::after {
    bottom: -0.7rem;
}

.hamburger--squeeze .hamburger-inner::before {
    will-change: transform;
    transition: background-color 0.15s ease-in-out, top 0.2s 0.24s ease, opacity 0.2s ease, transform 0.2s cubic-bezier(.4,0,.2,1);
}

.hamburger--squeeze .hamburger-inner::after {
    will-change: transform;
    transition: background-color 0.15s ease-in-out, bottom 0.2s 0.24s ease, transform 0.2s cubic-bezier(.4,0,.2,1);
}

.hamburger-button.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.24s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger-button.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.2s ease, opacity 0.2s 0.24s ease;
}

.hamburger-button.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.2s ease, transform 0.2s 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
}