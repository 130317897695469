// --------------------------------------------------
// reflex variables
// --------------------------------------------------

// Prefix
// -------------------------
// You can optionally namespace reflex classes to avoid clashing with other css frameworks
// e.g. setting $reflex-prefix as "reflex-" here would cause col-sm-6 to become reflex-col-sm-6
// Example: $reflex-prefix: reflex-;
// By default this is an empty string
$reflex-prefix: null !default;


// Legacy support
// -------------------------
// Reflex adds a lot of css propertie to support older browsers that don't support flexbox layout.
// If you prefer to use this as a purely flexbox grid without legacy support then set this to false
$legacy-support: false !default;

// Responsive visibility helpers
// -------------------------
// Reflex has some generic visibility classes to hide elements at different breakpoints.
// If you want to disable these then set this to false
$visibility-helpers: true !default;

// Grid
// -------------------------
$reflex-columns: 12 !default; // number of columns

// Breakpoints
// -------------------------
$reflex-xs: $screen-xs !default;
$reflex-sm: $screen-sm !default;
$reflex-md: $screen-md !default;
$reflex-lg: $screen-lg  !default;
$reflex-xl: $screen-xl !default;

$reflex-xxs-max: ($reflex-xs - 0.1);
$reflex-xs-max: ($reflex-sm - 0.1);
$reflex-sm-max: ($reflex-md - 0.1);
$reflex-md-max: ($reflex-lg - 0.1);
$reflex-lg-max: ($reflex-xl - 0.1);

// Spacing
// -------------------------
$reflex-grid-spacing: 0.7rem !default;

// Custom properties so others can reference the variables.
:root {
  --reflex-columns: #{ $reflex-columns };
  --reflex-grid-spacing: #{ $reflex-grid-spacing };
  --reflex-xs: #{ $reflex-xs };
  --reflex-sm: #{ $reflex-sm };
  --reflex-md: #{ $reflex-md };
  --reflex-lg: #{ $reflex-lg };
  --reflex-xl: #{ $reflex-xl };
  --reflex-xxs-max: #{ $reflex-xxs-max };
  --reflex-xs-max: #{ $reflex-xs-max };
  --reflex-sm-max: #{ $reflex-sm-max };
  --reflex-md-max: #{ $reflex-md-max };
  --reflex-lg-max: #{ $reflex-lg-max };
}
